import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {HashRouter as Router} from 'react-router-dom';
import {Provider} from 'react-redux';
import store from './store';
// if (window.location.protocol !== "https:") {
//   if (process.env.REACT_APP_APP_ENV === "prod") {
//     const url =
//       "https://" + window.location.hostname + window.location.pathname;
//     window.location.href = url;
//   }
// }

ReactDOM.render (
  <Provider store={store}>
    <Router basename={process.env.PUBLIC_URL || ''}>

      <App />
    </Router>
  </Provider>,
  document.getElementById ('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister ();
